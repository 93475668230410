import { defineComponent } from 'vue';
import DefaultDynamicStatusModalContent from '@/core/components/common/modals/DefaultDynamicStatusModalContent.vue';
import BaseModal from '@/core/components/ui/BaseModal.vue';
export default defineComponent({
    name: 'leave-page-modal',
    emits: ['on-confirm', 'on-cancel'],
    props: ['loading', 'show'],
    components: {
        DefaultDynamicStatusModalContent,
        BaseModal
    },
    data() {
        return {
            type: 'leavePage',
            content: {
                hasTwoButton: true,
                /* eslint-disable global-require */
                icon: `${require('@/assets/icons/leave-page-icon.svg')}`,
                /* eslint-disable global-require */
                statusText: 'Are you sure you want to leave this page?',
                hasDescription: true,
                descriptionText: 'Please note, if you leave this page without saving your draft, it will be deleted.',
                buttonActiveLabel: 'leave',
                buttonInActiveLabel: 'back'
            }
        };
    },
    methods: {
        onConfirm() {
            this.$emit('on-confirm');
        },
        onCancel() {
            this.$emit('on-cancel');
        }
    }
});
