<template>
    <div class="post-project-header flex-row ai-c jc-sb">
      <div class="post-project-header__wrapper flex-row ai-fs jc-c">
        <div class="post-project-header__back-anchor flex-row ai-c jc-c" @click="back">
            <el-icon :size="13" color="#0C0F4A"><ArrowLeftBold/></el-icon>
        </div>
        <p class="post-project-header__title default-text">{{currentRouteName === OVERVIEW ? 'Overview -' : ''}} {{title}}</p>
      </div>

      <!-- MODAL -->
    <LeavePageWarningModal ref="leaveModal" :show="getLeavePageModal" @on-cancel="confirmLeave(false)" @on-confirm="confirmLeave(true)"/>
    <CancelPostWarningModal ref="cancelPost" :show="getCancelPageModal" @on-cancel="confirmCancel(false)" @on-confirm="setCancelPageModal(false)"/>
    </div>
</template>
<script>
import { ArrowLeftBold } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import {
  ATTACHMENTS_SCREEN, CATEGORIES_SCREEN, DESCRIPTION_SCREEN, STATUS_SCREEN
} from '@/core/components/project-post/constants';
import CancelPostWarningModal from '@/modules/project-post/components/modal/CancelPostWarningModal.vue';
import LeavePageWarningModal from '@/modules/project-post/components/modal/LeavePageWarningModal.vue';
import { PROPERTIES_PROJECT_POST_STORE } from '@/store/modules/project-post';
import { PROJECT_POST_STORE } from '@/store/modules/projects/submodules/project-post';

const TITLE_STATUS = 'Project status';
const TITLE_DESCRIPTION = 'Project description';
const TITLE_EXPERTISE = 'Expertise';
const TITLE_ATTACHMENTS = 'Attachments';

const CUSTOMIZE = 'post-project';
const OVERVIEW = 'post-project-overview';

export default defineComponent({
  components: {
    ArrowLeftBold,
    LeavePageWarningModal,
    CancelPostWarningModal
  },

  data() {
    return {
      title: '',
      titleObj: {
        Status: TITLE_STATUS,
        Description: TITLE_DESCRIPTION,
        Categories: TITLE_EXPERTISE,
        Attachments: TITLE_ATTACHMENTS
      },
      isLeaveConfirm: false,
      isCancelConfirm: false,
      currentRoute: '',
      nextRoute: '',
      OVERVIEW
    };
  },

  computed: {
    ...mapGetters(PROPERTIES_PROJECT_POST_STORE, [
      'getJourneyCompleted',
      'getCurrentJourney',
      'getPostProjectConfirmationModal',
      'getCancelPageModal',
      'getLeavePageModal'
    ]),
    ...mapGetters(PROJECT_POST_STORE, [
      'epcRecommendationProjectDetails'
    ]),

    currentRouteName() {
      return this.$route.name;
    }
  },

  created() {
    this.initialize();
  },

  watch: {
    getCurrentJourney(value) {
      if (this.currentRouteName === CUSTOMIZE) {
        this.title = this.titleObj[value];
      }
    },

    'epcRecommendationProjectDetails.title': {
      immediate: true,
      deep: true,
      handler() {
        this.initialize();
      }
    }
  },

  methods: {
    ...mapActions(PROPERTIES_PROJECT_POST_STORE, [
      'setCurrentJourney',
      'setPostProjectConfirmationModal',
      'setCancelPageModal',
      'setLeavePageModal'
    ]),

    initialize() {
      if (this.currentRouteName === CUSTOMIZE) {
        this.title = TITLE_STATUS;
      }
      if (this.currentRouteName === OVERVIEW) {
        this.title = this.epcRecommendationProjectDetails['improvement-id-text'];
      }
    },

    back() {
      if (this.getCurrentJourney === STATUS_SCREEN) {
        this.backToPreviousPage();
        return;
      }
      if (this.getCurrentJourney === DESCRIPTION_SCREEN) {
        this.setCurrentJourney(STATUS_SCREEN);
        return;
      }
      if (this.getCurrentJourney === CATEGORIES_SCREEN) {
        this.setCurrentJourney(DESCRIPTION_SCREEN);
        return;
      }
      if (this.getCurrentJourney === ATTACHMENTS_SCREEN) {
        this.setCurrentJourney(CATEGORIES_SCREEN);
      }
    },

    backToPreviousPage() {
      this.$router.go(-1);
    },

    confirmLeave(event) {
      this.isLeaveConfirm = event;
      this.setLeavePageModal(false);
      if (event) {
        this.$router.push({ name: this.nextRoute });
      } else {
        this.$router.push({ name: this.currentRoute });
      }
    },

    confirmCancel(event) {
      this.isCancelConfirm = event;
      this.setCancelPageModal(false);
    },
  },
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.post-project-header {
  &__back-anchor {
    background: #fff;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    box-sizing: border-box;
    padding: .2rem;
    cursor: pointer;
  }

  &__wrapper {
    // gap: .5rem;
  }
  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
  }
  &__draft {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
  }
  &__cancel {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
    color: rgba(12, 15, 74, 0.5);
  }
}

@include media-xs-max-width() {
  .post-project-header {
    &__back-anchor {
      width: 25px;
      height: 25px;
    }
    &__title {
      font-size: 16px;
      line-height: 18px;
    }
  }
}
</style>
